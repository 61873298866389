import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ColorPickerModule } from 'ngx-color-picker';


import { CalendarModule, DateAdapter, CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT } from 'angular-calendar';
import localeEl from '@angular/common/locales/el';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';


import moment from 'moment-timezone';

registerLocaleData(localeEl);
export function momentAdapterFactory() {
  return adapterFactory(moment);
}

import { MatToolbarModule,
         MatMenuModule,
         MatIconModule,
         MatButtonModule,
         MatTableModule,
         MatDividerModule,
         MatInputModule,
         MatCardModule,
         MatSlideToggleModule,
         MatSelectModule,
         MatProgressBarModule,
         MatOptionModule,
         MatSidenavModule,
         MatListModule,
         MatPaginatorModule,
         MatSortModule,
         MatProgressSpinnerModule,
         MatTabsModule,
         MatDialogModule} from '@angular/material';
import { LoginComponent } from './login/login.component';
import { HomeComponent, UserEditDialog, ProgramEditDialog, ClassRegistrationsDialog } from './home/home.component';
import { registerLocaleData } from '@angular/common';
import { ReserveComponent, ClassRegistrationDialog } from './reserve/reserve.component';
import { RegisterComponent } from './register/register.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UserEditDialog,
    ProgramEditDialog,
    ClassRegistrationsDialog,
    ClassRegistrationDialog,
    ReserveComponent,
    RegisterComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    ColorPickerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatSidenavModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
  ],
  providers: [ {
    provide: MOMENT,
    useValue: moment,
  },],
  bootstrap: [AppComponent],
  entryComponents: [UserEditDialog,ProgramEditDialog,ClassRegistrationsDialog, ClassRegistrationDialog]
})
export class AppModule { }

import { HandlerService } from './../handler.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Inject, TemplateRef, ChangeDetectorRef, } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarDateFormatter, DAYS_OF_WEEK, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, addMinutes, } from 'date-fns';
import { ViewPeriod } from 'calendar-utils';
import moment from 'moment-timezone';
import RRule from 'rrule';
import md5 from 'md5';

import { CustomDateFormatter } from './custom-date-formatter.provider';

import Swal from 'sweetalert2/src/sweetalert2.js'
import { Subject, BehaviorSubject } from 'rxjs';

interface ProgramClass {
  d: number, //fay of week
  t: string //time string 24h (ex 11:00)
}
interface Program {
  id?:number,
  name: string,
  color: any,
  duration: number,
  active: boolean,
  max: number,
  classes?: ProgramClass[],
  regs?:any
}
interface RecurringEvent {
  id?:string,
  title: string;
  color: any;
  timeStart?: any;
  duration?: number;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}
moment.tz.setDefault('Europe/Athens');


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter
  }]
})
export class HomeComponent implements OnInit {
  currentTab = 1;
  opened: boolean = true;

  /**** USERS ******/
  displayedColumns: string[] = ['id', 'username', 'name', 'email', 'phone', 'gymid', 'created', 'actions'];
  dataSource: MatTableDataSource<any>;
  isLoadingResults: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /**** END USERS ******/
  /**** PROGRAMS ******/
  programsDisplayColumns: string[] = ['name', 'duration', 'max', 'classes', 'actions'];
  programsData: Program[];
  programsDataSource = new BehaviorSubject([]);

  classToEvent:any=[];
  reservationsToClass:any=[];

  programsClassesToHTML(classes) {
    let html = ""
    for (let i = 0; i < classes.length; i++) {
      html += this.RRuleToGreekDay(classes[i].d) + " " + classes[i].t + ", "
    }
    return html;
  }
  RRuleToGreekDay(day) {
    switch (day) {
      case 0: return "Κυριακή"
      case 1: return "Δευτέρα"
      case 2: return "Τρίτη"
      case 3: return "Τετάρτη"
      case 4: return "Πέμπτη"
      case 5: return "Παρασκευή"
      case 6: return "Σάββατο"
      default: return "Αγν. Ημέρα";
    }
  }
  renderRecurringEvents() {
    this.recurringEvents = [];
    this.programsData.forEach(program => {
      if (program.active) {
        program.classes.forEach(cls => {
          this.recurringEvents.push({
            title: program.name + " " + cls.t,
            color: program.color,
            rrule: {
              freq: RRule.WEEKLY,
              byweekday: [cls.d],
            },
            timeStart: cls.t,
            duration: program.duration
          });
        })
      }
    })
    this.programsDataSource.next(this.programsData)
  }
  //https://www.npmjs.com/package/angular-calendar-scheduler
  /**** END PROGRAMS ******/

  /****CALENDAR */
  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;
  viewDate = moment().toDate();

  locale: string = 'el';

  refresh: Subject<any> = new Subject();
  recurringEvents: RecurringEvent[] = [];

  events: CalendarEvent[] = [];

  viewPeriod: ViewPeriod;

  activeDayIsOpen: boolean = false; /****END CALENDAR */

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  /*deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }*/
  setView(view: CalendarView) {
    this.view = view;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  clickedEvent(event) {
    let class_id = moment(event.start).unix();
    this.service.getReservationsByClassId(class_id).subscribe(clasresresp=>{
      if(clasresresp && clasresresp.code){
        switch(clasresresp.code){
          case 200: 
          const dialogRef = this.dialog.open(ClassRegistrationsDialog, {
            width: '600px',
            data: {reservations:clasresresp.data,title:event.title.replace('<br>','')}
          });
          break;
          default: Swal.fire({icon:'warning',text:'Δεν υπάρχουν καταχωρημένες κρατήσεις.'})
        }
      }else{
        Swal.fire({icon:'error',text:'Αποτυχία ανάκτησης κρατήσεων'})
      }
    })
  }
  addProgram() {
    this.programsData.push({ name: '', color: { primary: '', secondary: '' }, classes: [], duration: 0, max: 0, active: true })
    this.programsDataSource.next(this.programsData)
    this.editProgram(this.programsData[this.programsData.length - 1])
  }
  editProgram(pr) {
    const dialogRef = this.dialog.open(ProgramEditDialog, {
      width: '600px',
      data: { program: pr, new: pr.name ? false : true }
    });

    dialogRef.afterClosed().subscribe(result => {
      result.program.color.secondary = this.LightenDarkenColor(result.program.color.primary, 150);
      if (result.new) {
        //create program
        console.log("Create program", result.program);
        this.service.createProgram(result.program).subscribe(prCrResult => {
          if (prCrResult && prCrResult.code) {
            switch (prCrResult.code) {
              case 200: Swal.fire({ icon: 'success', text: 'Αποθηκεύτηκε', timer: 750 }); break;
              default:
                Swal.fire({ icon: 'warning', text: 'Αδυναμία αποθήκευσης αλλαγών: ' + prCrResult.message });
                break;
            }
          } else {
            Swal.fire({ icon: 'error', text: 'Αδυναμία αποθήκευσης αλλαγών: ' + prCrResult.message });
          }
        })
      } else {
        //edit program
        console.log("Edit program", result.program);
        this.service.editProgram(result.program).subscribe(prEdResult => {
          if (prEdResult && prEdResult.code) {
            switch (prEdResult.code) {
              case 200: Swal.fire({ icon: 'success', text: 'Αποθηκεύτηκε', timer: 750 }); break;
              default:
                Swal.fire({ icon: 'warning', text: 'Αδυναμία αποθήκευσης αλλαγών: ' + prEdResult.message });
                break;
            }
          } else {
            Swal.fire({ icon: 'error', text: 'Αδυναμία αποθήκευσης αλλαγών: ' + prEdResult.message });
          }
        })
      }
      console.log('The dialog was closed', result);
    });
  }
  deleteProgram(pr){
   Swal.fire({
     icon:'info',
     text:'Θέλετε να καταργήσετε τις αίθουσες '+pr.name+'; ',
     showCancelButton:true,
     showConfirmButton:true,
     confirmButtonText:'Ναι, κατάργηση',
     cancelButtonText:'Όχι, επιστροφή'
   }).then(userResponse=>{
     if(userResponse.value){
      this.service.deleteProgram(pr).subscribe(delPr=>{
        if(delPr && delPr.code){
            switch(delPr.code){
              case 200: 
              let idx = this.programsData.findIndex((p,i)=>{return p.id==delPr.id})
              this.programsData.splice(idx,1);
              this.renderRecurringEvents();
              Swal.fire({icon:'success',text:delPr.message})
              break;
              default: 
              Swal.fire({icon:'warning',text:'Αδυναμία διαγραφής: '+delPr.message})
              break;
            }
        }else{
          Swal.fire({icon:'error',text:'Αδυναμία διαγραφής: '+delPr.message})
        }
      })
     }
   })
  }
  updateCalendarEvents(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    if (
      !this.viewPeriod ||
      !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender.period.end)
    ) {
      this.programsData.forEach(prog=>{
        if(prog.regs.length>0){
          let classesReg = [];
          if(prog.regs.includes(",")){
            classesReg = prog.regs.split(",")
          }else{
            classesReg = [prog.regs]
          }
          classesReg.forEach(clsReg => {
              let idx = this.reservationsToClass.findIndex(item=>{return item.class_id==clsReg});
              if(idx<0) this.reservationsToClass.push({class_id:clsReg,max:prog.max,reservations:1})
              else this.reservationsToClass[idx].reservations++;
          });
        }
      })

      this.renderRecurringEvents()
      this.viewPeriod = viewRender.period;
      this.events = [];

      this.recurringEvents.forEach((event) => {
        let tempMom = addHours(viewRender.period.start, event.timeStart.split(':')[0]);
        tempMom = addMinutes(tempMom, event.timeStart.split(':')[1])
        let startMoment = moment(tempMom).toDate();
        const rule: RRule = new RRule({
          ...event.rrule,
          // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
          dtstart: startMoment,
          until: moment(viewRender.period.end).toDate(),
        });
        const { title, color } = event;
        rule.all().forEach((date) => {
          this.classToEvent.push({class:moment(addDays(moment(date).toDate(), -1)).unix(),program:event.id});
          let res_i = this.reservationsToClass.findIndex(item=>{return item.class_id==moment(addDays(moment(date).toDate(), -1)).unix()})
          let finalTitle = title;
          if(res_i>-1){
            if(this.reservationsToClass[res_i].reservations>=this.reservationsToClass[res_i].max) finalTitle += "<br> * [FULL] *"
            else finalTitle += "<br> ["+this.reservationsToClass[res_i].reservations+"/"+this.reservationsToClass[res_i].max+"]"
          }else{
            finalTitle += " "
          }

          this.events.push({
            title: finalTitle,
            color,
            start: addDays(moment(date).toDate(), -1),
            end: addMinutes(addDays(moment(date).toDate(), -1), event.duration),
            allDay: false
          });
        });
      });
      this.cdr.detectChanges();
    }
  }
  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  constructor(private router: Router, private service: HandlerService, public dialog: MatDialog, private cdr: ChangeDetectorRef) {

  }
  ngOnInit() {
    this.programsDataSource.next(this.programsData)
    let cookieUser:any = this.service.getCookie('user');
    try{
      cookieUser = JSON.parse(decodeURIComponent(atob(cookieUser)));
    }catch(e){
      // DISALLOW unregistered user.
      this.router.navigate(['/login'])
    }
    if (cookieUser.username && cookieUser.username == "admin" && cookieUser.token && cookieUser.token.length > 15) {
      this.service.setUser(cookieUser);
    }else{
      this.service.setUser({});
      this.router.navigate(['/login'])
    }

    this.isLoadingResults = true;
    this.service.fetchUsers().subscribe(resp => {
      this.isLoadingResults = false;
      if (resp && resp.code) {
        switch (resp.code) {
          case 200:
            console.log(resp.data)
            this.dataSource = new MatTableDataSource(resp.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            break;
          case 403:
          case 407:
            this.logout();
            break;
          default:
            Swal.fire({ icon: 'warning', text: resp.message })
            break;
        }
      } else {
        Swal.fire({ icon: 'error', text: 'Αδυναμία ανάκτησης δεδομένων.' })
      }
    });
    this.service.fetchProgram().subscribe(fprograms => {
      if (fprograms && fprograms.code) {
        switch (fprograms.code) {
          case 200:
            this.programsData = fprograms.data
            this.renderRecurringEvents();
            break;
          default: break;
        }
      } else {
        Swal.fire({ icon: 'warning', text: 'Αδυναμία ανάκτησης καταχωρημένων προγραμμάτων.' });
      }
    })
  }
  goToTab(i) {
    //0 users, 1=programs, 2=calendar, 3 = settings
    this.currentTab = i;
    this.cdr.detectChanges();
    this.refresh.next();

  }
  isLoggedIn() {
    if (this.service.getUser() && this.service.getUser().username && this.service.getUser().username.length > 1 && this.service.getUser().token) return true;
    return false;
  }
  logout() {
    this.service.setUser({})
    this.router.navigate(['/login'])
  }
  applyFilter(event: Event) {

    let filterValue = "";
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  createUser(user) {
    this.isLoadingResults = true;
    this.service.createUser(user).subscribe(createUserResponse => {
      this.isLoadingResults = false;
      console.log(createUserResponse);
      if (createUserResponse && createUserResponse.code) {
        switch (createUserResponse.code) {
          case 200:
            console.log(createUserResponse.data)
            Swal.fire({ icon: 'success', text: createUserResponse.message, timer: 1000 })
            this.ngOnInit()
            break;
          case 403:
          case 407:
            this.logout();
            break;
          default:
            Swal.fire({ icon: 'warning', text: createUserResponse.message })
            break;
        }
      } else {
        Swal.fire({ icon: 'error', text: 'Αδυναμία ανάκτησης δεδομένων.' })
      }
    });
  }
  editUser(user) {
    const dialogRef = this.dialog.open(UserEditDialog, {
      width: '600px',
      data: { user: user, new: user.username ? false : true }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.new) { this.createUser(result.user); return; }
      this.isLoadingResults = true;
      this.service.editUser(result.user).subscribe(editUserResponse => {
        this.isLoadingResults = false;
        if (editUserResponse && editUserResponse.code) {
          switch (editUserResponse.code) {
            case 200:
              console.log(editUserResponse.data)
              Swal.fire({ icon: 'success', text: editUserResponse.message, timer: 1000 })
              break;
            case 403:
            case 407:
              this.logout();
              break;
            default:
              Swal.fire({ icon: 'warning', text: editUserResponse.message })
              break;
          }
        } else {
          Swal.fire({ icon: 'error', text: 'Αδυναμία ανάκτησης δεδομένων.' })
        }
      });
    });
  }
  setActive(user, active) {
    this.isLoadingResults = true;
    user.active = active;
    this.service.editUser(user).subscribe(editUserResponse => {
      this.isLoadingResults = false;
      if (editUserResponse && editUserResponse.code) {
        switch (editUserResponse.code) {
          case 200:
            console.log(editUserResponse.data)
            Swal.fire({ icon: 'success', text: editUserResponse.message })
            break;
          case 403:
          case 407:
            this.logout();
            break;
          default:
            Swal.fire({ icon: 'warning', text: editUserResponse.message })
            break;
        }
      } else {
        Swal.fire({ icon: 'error', text: 'Αδυναμία ανάκτησης δεδομένων.' })
      }
    });
  }
  deleteUser(user) {

    Swal.fire({ icon: 'warning', text: 'Θέλετε να διαγράψετε τον χρήστη ' + user.name + '?', showCancelButton: true, configmButtontext: 'Διαγραφή', cancelButtonText: 'Άκυρο' }).then(rslt => {
      if (rslt.value) {
        this.isLoadingResults = true;
        this.service.deleteUser(user).subscribe(editUserResponse => {
          this.isLoadingResults = false;
          if (editUserResponse && editUserResponse.code) {
            switch (editUserResponse.code) {
              case 200:
                console.log(editUserResponse.data)
                Swal.fire({ icon: 'success', text: editUserResponse.message, timer: 1000 }).then(() => {
                  this.ngOnInit()
                })
                break;
              case 403:
              case 407:
                this.logout();
                break;
              default:
                Swal.fire({ icon: 'warning', text: editUserResponse.message })
                break;
            }
          } else {
            Swal.fire({ icon: 'error', text: 'Αδυναμία ανάκτησης δεδομένων.' })
          }
        });
      }
    })
  }



}



@Component({
  selector: 'user-edit-dialog',
  templateUrl: 'user-edit-dialog.html',
})
export class UserEditDialog {

  constructor(
    public dialogRef: MatDialogRef<UserEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  save(): void {
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'program-edit-dialog',
  templateUrl: 'program-edit-dialog.html',
})
export class ProgramEditDialog {

  constructor(
    public dialogRef: MatDialogRef<ProgramEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
  }
  daysOfWeek: any = [{ name: 'Δευτέρα', value: 1 }, { name: 'Τρίτη', value: 2 }, { name: 'Τετάρτη', value: 3 }, { name: 'Πέμπτη', value: 4 }, { name: 'Παρασκευή', value: 5 }, { name: 'Σαββάτο', value: 6 }, { name: 'Κυριακή', value: 7 }];

  RRuleToGreekDay(day) {
    switch (day) {
      case 0: return "Κυριακή"
      case 1: return "Δευτέρα"
      case 2: return "Τρίτη"
      case 3: return "Τετάρτη"
      case 4: return "Πέμπτη"
      case 5: return "Παρασκευή"
      case 6: return "Σάββατο"
      default: return "Αγν. Ημέρα";
    }
  }
  removeClass(idx) {
    this.data.program.classes.splice(idx, 1);
  }
  addClass() {
    this.data.program.classes.push({ d: 1, t: '08:00' });
  }
  save(): void {
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'class-registrations-dialog',
  templateUrl: 'class-registrations-dialog.html',
})
export class ClassRegistrationsDialog {

  constructor(
    public dialogRef: MatDialogRef<ClassRegistrationsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


export interface User{
  id?:number,
  name?:string,
  username?:string,
  token?:string
}

@Injectable({
  providedIn: 'root'
})
export class HandlerService {

  private user:User={};
  private datatopass:any=null;
  private subdomain:string="";
  private token:string="";

  private API_BASE = "http://reservoir.ctzoum.eu:3000";
  constructor(private http:HttpClient, private router:Router, public cookieService:CookieService) { }

  getUser(){return this.user}
  setUser(user:User){
    this.user=user;
    this.setCookie('user',btoa(encodeURIComponent(JSON.stringify(this.user))));
  }

  setToken(tok){this.token=tok;}
  getToken(){return this.token;}

  setCookie(key,val){this.cookieService.set(key,val)}
  getCookie(key){let ret=this.cookieService.get(key);console.log('cookie',key,ret);return ret}

  login(username,password){
    const headers = {'Content-Type': 'application/json'}
    const body = {user: username,password:password}
    return this.http.post<any>(this.API_BASE+'/auth', body, { headers })
  }

  /* USERS CALLS*/
  fetchUsers(){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'list',access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/users', body, { headers })
  }
  register(user){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'register',data:user}
    return this.http.post<any>(this.API_BASE+'/users', body, { headers })
  }
  createUser(user){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'create',data:user,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/users', body, { headers })
  }
  editUser(user){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'edit',data:user,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/users', body, { headers })
  }
  deleteUser(user){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'delete',data:user,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/users', body, { headers })
  }
  /* END USERS CALLS*/

  /* Programs CALLS*/
  fetchProgram(){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'list',access_token:this.user.token,guest:(this.user.token) ? false : true}
    return this.http.post<any>(this.API_BASE+'/programs', body, { headers })
  }
  createProgram(program){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'create',data:program,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/programs', body, { headers })
  }
  editProgram(program){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'edit',data:program,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/programs', body, { headers })
  }
  deleteProgram(program){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'delete',data:program,access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/programs', body, { headers })
  }
  /* END Programs CALLS*/

  /* Reservation CALLS*/
  createReservation(class_id,program_id){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'add',data:{user_id:this.user.id,class_id:class_id,program_id:program_id},access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/regs', body, { headers })
  }
  getReservationsByClassId(class_id){
    const headers = {'Content-Type': 'application/json'}
    const body = {action:'get',data:{class_id:class_id},access_token:this.user.token}
    return this.http.post<any>(this.API_BASE+'/regs', body, { headers })
  }

  /* END Reservation CALLS*/

 
}

import { Router } from '@angular/router';
import { HandlerService } from './../handler.service';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarDateFormatter, DAYS_OF_WEEK, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, addMinutes, } from 'date-fns';
import { ViewPeriod } from 'calendar-utils';
import moment from 'moment-timezone';
import RRule from 'rrule';
import md5 from 'md5';
import Swal from 'sweetalert2/src/sweetalert2.js'

import { CustomDateFormatter } from '../home/custom-date-formatter.provider';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

interface ProgramClass {
  d: number, //fay of week
  t: string //time string 24h (ex 11:00)
}
interface Program {
  id?:number,
  name: string,
  color: any,
  duration: number,
  active: boolean,
  max: number,
  classes?: ProgramClass[],
  regs?:any
}
interface RecurringEvent {
  id?:number,
  title: string;
  color: any;
  timeStart?: any;
  duration?: number;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}
moment.tz.setDefault('Europe/Athens');


@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.css']
})
export class ReserveComponent implements OnInit {
  isLoadingResults:boolean=false;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  viewDate = moment().toDate();

  locale: string = 'el';

  refresh: Subject<any> = new Subject();
  recurringEvents: RecurringEvent[] = [];

  events: CalendarEvent[]=[];
  classToEvent:any=[];
  reservationsToClass:any=[];

  viewPeriod: ViewPeriod;

  activeDayIsOpen: boolean = false; /****END CALENDAR */

  programsData: Program[]=[];
  programsDataSource = new BehaviorSubject([]);

  loggedIn:boolean=false;



  constructor(private service:HandlerService, private router:Router,private cdr: ChangeDetectorRef, public dialog: MatDialog) { }

  ngOnInit() {
    let cookieUser:any = this.service.getCookie('user');
    try{ cookieUser = JSON.parse(decodeURIComponent(atob(cookieUser))); }catch(e){ cookieUser={}; }
    if (cookieUser.username && cookieUser.username.length > 1 && cookieUser.token && cookieUser.token.length > 15) {
      this.service.setUser(cookieUser);
      this.loggedIn = true;
    }else{
     // NOT logged in.
    }
    this.reloadPrograms();
  }


  reloadPrograms(){
    this.isLoadingResults = true;
    this.programsData = [];
    this.recurringEvents = [];
    this.reservationsToClass = [];
    this.events = [];
    this.service.fetchProgram().subscribe(fprograms => {
      this.isLoadingResults = false;
      if (fprograms && fprograms.code) {
        switch (fprograms.code) {
          case 200:
            this.programsData = fprograms.data
            this.programsData.forEach(prog=>{
              if(prog.regs.length>0){
                let classesReg = [];
                if(prog.regs.includes(",")){
                  classesReg = prog.regs.split(",")
                }else{
                  classesReg = [prog.regs]
                }
                classesReg.forEach(clsReg => {
                    let idx = this.reservationsToClass.findIndex(item=>{return item.class_id==clsReg});
                    if(idx<0) this.reservationsToClass.push({class_id:clsReg,max:prog.max,reservations:1})
                    else this.reservationsToClass[idx].reservations++;
                });
              }
            })
            this.renderRecurringEvents();
            this.recurringEvents.forEach((event) => {
              let tempMom = addHours(this.viewPeriod.start, event.timeStart.split(':')[0]);
              tempMom = addMinutes(tempMom, event.timeStart.split(':')[1])
              let startMoment = moment(tempMom).toDate();
              const rule: RRule = new RRule({
                ...event.rrule,
                // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
                dtstart: startMoment,
                until: moment(this.viewPeriod.end).toDate(),
              });
              const { title, color } = event;
              rule.all().forEach((date) => {
                this.classToEvent.push({class:moment(addDays(moment(date).toDate(), -1)).unix(),program:event.id});
                let res_i = this.reservationsToClass.findIndex(item=>{return item.class_id==moment(addDays(moment(date).toDate(), -1)).unix()})
                let finalTitle = title;
                if(res_i>-1){
                  if(this.reservationsToClass[res_i].reservations>=this.reservationsToClass[res_i].max) finalTitle += " * [FULL] *"
                  else finalTitle += " "+this.reservationsToClass[res_i].reservations+"/"+this.reservationsToClass[res_i].max
                }else{
                  finalTitle += " "
                }
                
                this.events.push({
                  title: finalTitle,
                  color,
                  start: addDays(moment(date).toDate(), -1),
                  end: addMinutes(addDays(moment(date).toDate(), -1), event.duration),
                  allDay: false
                });
              });
            });
            this.cdr.detectChanges();
            this.refresh.next();

            break;
          default: break;
        }
      } else {
        Swal.fire({ icon: 'warning', text: 'Αδυναμία ανάκτησης καταχωρημένων προγραμμάτων.' });
      }
    })
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  clickedEvent(event) {
    let class_id = moment(event.start).unix();
    let program_id = this.classToEvent[this.classToEvent.findIndex(d=>{return d.class==class_id})].program;

    const dialogRef = this.dialog.open(ClassRegistrationDialog, {
      width: '600px',
      data: { class_id:class_id, program_id:program_id,user:this.service.getUser() }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.value){
        this.service.createReservation(class_id,program_id).subscribe(resresp=>{
          if(resresp && resresp.code){
            switch(resresp.code){
              case 200: 
              Swal.fire({icon:'success',html:'Η κράτηση καταχωρήθηκε.',timer:1000});
              this.reloadPrograms();
              break;
              default:  Swal.fire({icon:'warning',html:'Αποτυχία καταχώρησης κράτησης: <br>'+resresp.message})
            }
          }else{
            Swal.fire({icon:'error',html:'Αποτυχία καταχώρησης κράτησης: <br>'+resresp.message})
          }
        })
      }
      if(result.login){
        this.router.navigate(['/login'])
      }
    })



    
  }
  setView(view){
    this.view=view;
  }

  updateCalendarEvents(viewRender:CalendarWeekViewBeforeRenderEvent): void {
    if (!this.viewPeriod ||!moment(this.viewPeriod.start).isSame(viewRender.period.start) ||!moment(this.viewPeriod.end).isSame(viewRender.period.end)) {
      this.renderRecurringEvents()
      this.viewPeriod = viewRender.period;
      this.events = [];

      this.recurringEvents.forEach((event) => {
        let tempMom = addHours(viewRender.period.start, event.timeStart.split(':')[0]);
        tempMom = addMinutes(tempMom, event.timeStart.split(':')[1])
        let startMoment = moment(tempMom).toDate();
        const rule: RRule = new RRule({
          ...event.rrule,
          // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
          dtstart: startMoment,
          until: moment(viewRender.period.end).toDate(),
        });
        const { title, color } = event;
        rule.all().forEach((date) => {
          let finalTitle = title;
          if (moment(date).unix() == "1587459600") finalTitle = "[FULL] !! " + finalTitle;
          this.events.push({
            title: finalTitle,
            color,
            start: addDays(moment(date).toDate(), -1),
            end: addMinutes(addDays(moment(date).toDate(), -1), event.duration),
            allDay: false,
          });
        });
      });
      this.cdr.detectChanges();
    }
  }
  programsClassesToHTML(classes) {
    let html = ""
    for (let i = 0; i < classes.length; i++) {
      html += this.RRuleToGreekDay(classes[i].d) + " " + classes[i].t + ", "
    }
    return html;
  }
  RRuleToGreekDay(day) {
    switch (day) {
      case 0: return "Κυριακή"
      case 1: return "Δευτέρα"
      case 2: return "Τρίτη"
      case 3: return "Τετάρτη"
      case 4: return "Πέμπτη"
      case 5: return "Παρασκευή"
      case 6: return "Σάββατο"
      default: return "Αγν. Ημέρα";
    }
  }
  renderRecurringEvents() {
    this.recurringEvents = [];
    this.programsData.forEach(program => {
      if (program.active) {
        program.classes.forEach(cls => {
          this.recurringEvents.push({
            id:program.id,
            title: program.name + " " + cls.t,
            color: program.color,
            rrule: {
              freq: RRule.WEEKLY,
              byweekday: [cls.d],
            },
            timeStart: cls.t,
            duration: program.duration
          });
        })
      }
    })

    this.refresh.next(this.recurringEvents)
  }

  login(){
    this.router.navigate(['/login'])
  }
  logout(){
    this.service.setUser({})
    window.location.reload();
  }

}


@Component({
  selector: 'reserve-dialog',
  templateUrl: 'reserve-dialog.html',
})
export class ClassRegistrationDialog {

  constructor(
    public dialogRef: MatDialogRef<ClassRegistrationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  reserve(){
    this.dialogRef.close({value:true});
  }
  login(){
    this.dialogRef.close({login:true});
  }

}
import { Router } from '@angular/router';
import { HandlerService } from './handler.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public service:HandlerService,private router:Router){}
  title = 'gym-reservoir';
}

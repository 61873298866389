import { HandlerService } from './../handler.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js'
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
credentials:any={username:'',password:''};
loading:boolean=false;
  constructor(private router:Router,private service:HandlerService) { }

  ngOnInit() {
    this.service.setUser({});
  }
  login(){
    if(this.credentials.username.length<3){
      Swal.fire({icon:'error',text:'Συμπληρώστε το username.'});return;
    }
    if(this.credentials.password.length<4){
      Swal.fire({icon:'error',text:'Συμπληρώστε το password.'});return;
    }
    this.loading=true;
    this.service.login(this.credentials.username,this.credentials.password).subscribe(loginResp=>{
      console.log(loginResp);
      this.loading=false;
      if(loginResp && loginResp.code){
        switch(loginResp.code){
          case 200:
            Swal.fire({icon:'success',timer:1500}).then(()=>{
              this.service.setUser({id:loginResp.data.id,name:loginResp.data.name,username:loginResp.data.username,token:loginResp.access_token});
              if(this.service.getUser().username=="admin") this.router.navigate(['/admin']);
              else this.router.navigate(['/']);
            });
            break;
          default:
            Swal.fire({icon:'warning',html:loginResp.message});
            break;
        }
      }else{
        Swal.fire({icon:'error',html:'Σφάλμα σύνδεσης στο σύστημα.<br>Επικοινωνήστε με τον διαχειριστή συστήματος.'});
      }
    })
  }

  register(){ this.router.navigate(['/register']) }
  home(){ this.router.navigate(['/']) }

}

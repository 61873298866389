import { Router } from '@angular/router';
import { HandlerService } from './../handler.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  loading:boolean=false;
  user:any={
    name:'',
    phone:'',
    username:'',
    email:'',
    password:'',
    gymid:''
  };

  constructor(private service:HandlerService, private router:Router) { }

  ngOnInit() {
  }

  register(){
    if(!this.user.name || this.user.name.length<3){return;}
    if(!this.user.phone || this.user.phone.length<10){return;}
    if(!this.user.username || this.user.phone.username<4){return;}
    if(!this.user.email || this.user.phone.email < 4 || !this.user.email.includes('@') || !this.user.email.includes('.')){return;}
    if(!this.user.password || this.user.phone.password < 4){return;}
    if(!this.user.gymid || this.user.phone.gymid < 3){return;}

    this.service.register(this.user).subscribe(regRes=>{
      if(regRes && regRes.code){
        switch(regRes.code){
          case 200: 
          Swal.fire({icon:'success',html:regRes.message}).then(()=>{
            this.router.navigate(['/'])
          });
          break;
          default: Swal.fire({icon:'warning',html:'Αδυναμία καταχώρησης χρήστη.<br>'+regRes.message})
        }
      }else{
        Swal.fire({icon:'error',text:'Αδυναμία καταχώρησης χρήστη. Επικοινωνήστε με τον διαχειριστή.'})
      }
    })
  }

}
